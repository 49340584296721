.swiper-pagination-bullet-active {
  background: var(--red) !important;
}

.swiper-wrapper {
  align-items: flex-end;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw !important;
}
