* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Delivery';
}

a {
  text-decoration: none;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

:root {
  --red: #d40511;
  --yellow: #fc0;
  --swiper-theme-color: #d40511;
}
