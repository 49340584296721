/* @font-face {
  font-family: 'Delivery';
  font-style: normal;
  font-weight: 200;
  src: url('/src/fonts/SourceHanSansSC-Light.woff2') format('woff2'),
    url('/src/fonts/SourceHanSansSC-Light.otf') format('opentype');
  unicode-range: U+24F-2FA1F;
}

@font-face {
  font-family: 'Delivery';
  font-style: italic;
  font-weight: 200;
  src: url('/src/fonts/SourceHanSansSC-Light.woff2') format('woff2'),
    url('/src/fonts/SourceHanSansSC-Light.otf') format('opentype');
  unicode-range: U+24F-2FA1F;
}

@font-face {
  font-family: 'Delivery';
  font-style: normal;
  font-weight: 400;
  src: url('/src/fonts/SourceHanSansSC-Regular.woff2') format('woff2'),
    url('/src/fonts/SourceHanSansSC-Regular.otf') format('opentype');
  unicode-range: U+24F-2FA1F;
}

@font-face {
  font-family: 'Delivery';
  font-style: italic;
  font-weight: 400;
  src: url('/src/fonts/SourceHanSansSC-Regular.woff2') format('woff2'),
    url('/src/fonts/SourceHanSansSC-Regular.otf') format('opentype');
  unicode-range: U+24F-2FA1F;
}

@font-face {
  font-family: 'Delivery';
  font-style: normal;
  font-weight: 700;
  src: url('/src/fonts/SourceHanSansSC-Bold.woff2') format('woff2'), url('/src/fonts/SourceHanSansSC-Bold.otf') format('opentype');
  unicode-range: U+24F-2FA1F;
}

@font-face {
  font-family: 'Delivery';
  font-style: italic;
  font-weight: 700;
  src: url('/src/fonts/SourceHanSansSC-Bold.woff2') format('woff2'), url('/src/fonts/SourceHanSansSC-Bold.otf') format('opentype');
  unicode-range: U+24F-2FA1F;
} */

@font-face {
  font-family: 'Delivery';
  font-style: normal;
  font-weight: 200;
  src: url('/src/fonts/Delivery_W_Lt.woff2') format('woff2'), url('/src/fonts/Delivery_W_Lt.woff') format('woff');
  unicode-range: U+20-24F, U+370-52F, U+2DE0-2DFF, U+A640-A69F, U+1C80-1C8F, U+600-6FF, U+750-77F, U+8A0-8FF, U+B50-DFF,
    U+FE70-FEFF;
}

@font-face {
  font-family: 'Delivery';
  font-style: italic;
  font-weight: 200;
  src: url('/src/fonts/Delivery_W_LtIt.woff2') format('woff2'), url('/src/fonts/Delivery_W_LtIt.woff') format('woff');
  unicode-range: U+20-24F, U+370-52F, U+2DE0-2DFF, U+A640-A69F, U+1C80-1C8F, U+600-6FF, U+750-77F, U+8A0-8FF, U+B50-DFF,
    U+FE70-FEFF;
}

@font-face {
  font-family: 'Delivery';
  font-style: normal;
  font-weight: 400;
  src: url('/src/fonts/Delivery_W_Rg.woff2') format('woff2'), url('/src/fonts/Delivery_W_Rg.woff') format('woff');
  unicode-range: U+20-24F, U+370-52F, U+2DE0-2DFF, U+A640-A69F, U+1C80-1C8F, U+600-6FF, U+750-77F, U+8A0-8FF, U+B50-DFF,
    U+FE70-FEFF;
}

@font-face {
  font-family: 'Delivery';
  font-style: italic;
  font-weight: 400;
  src: url('/src/fonts/Delivery_W_It.woff2') format('woff2'), url('/src/fonts/Delivery_W_It.woff') format('woff');
  unicode-range: U+20-24F, U+370-52F, U+2DE0-2DFF, U+A640-A69F, U+1C80-1C8F, U+600-6FF, U+750-77F, U+8A0-8FF, U+B50-DFF,
    U+FE70-FEFF;
}

@font-face {
  font-family: 'Delivery';
  font-style: normal;
  font-weight: 700;
  src: url('/src/fonts/Delivery_W_Bd.woff2') format('woff2'), url('/src/fonts/Delivery_W_Bd.woff') format('woff');
  unicode-range: U+20-24F, U+370-52F, U+2DE0-2DFF, U+A640-A69F, U+1C80-1C8F, U+600-6FF, U+750-77F, U+8A0-8FF, U+B50-DFF,
    U+FE70-FEFF;
}

@font-face {
  font-family: 'Delivery';
  font-style: italic;
  font-weight: 700;
  src: url('/src/fonts/Delivery_W_BdIt.woff2') format('woff2'), url('/src/fonts/Delivery_W_BdIt.woff') format('woff');
  unicode-range: U+20-24F, U+370-52F, U+2DE0-2DFF, U+A640-A69F, U+1C80-1C8F, U+600-6FF, U+750-77F, U+8A0-8FF, U+B50-DFF,
    U+FE70-FEFF;
}

@font-face {
  font-family: 'Delivery';
  font-style: normal;
  font-weight: 200;
  font-stretch: condensed;
  src: url('/src/fonts/Delivery_W_CdLt.woff2') format('woff2'), url('/src/fonts/Delivery_W_CdLt.woff') format('woff');
  unicode-range: U+20-24F, U+370-52F, U+2DE0-2DFF, U+A640-A69F, U+1C80-1C8F, U+600-6FF, U+750-77F, U+8A0-8FF, U+B50-DFF,
    U+FE70-FEFF;
}

@font-face {
  font-family: 'Delivery';
  font-style: normal;
  font-weight: 800;
  font-stretch: condensed;
  src: url('/src/fonts/Delivery_W_CdBlk.woff2') format('woff2'), url('/src/fonts/Delivery_W_CdBlk.woff') format('woff');
  unicode-range: U+20-24F, U+370-52F, U+2DE0-2DFF, U+A640-A69F, U+1C80-1C8F, U+600-6FF, U+750-77F, U+8A0-8FF, U+B50-DFF,
    U+FE70-FEFF;
}
